<template>
	<div class="app-container interviewAppraisal" v-loading="loading">
		<div class="titlebox">
			<el-button size="medium" type="text" @click="$router.go(-1)">返回</el-button>
			<div class="title">{{ $route.query.name }}面试评价</div>
		</div>
		<section class="sections-container">
			<el-form ref="appraisalForm" :model="appraisalForm" label-width="220px">
				<div class="section-wrap">
					<h3 class="title-icon">
						面试评价
						<el-tag
							v-if="$route.name == 'interviewAppraisalInfo'"
							:type="appraisalForm.status == '2' ? 'success' : 'danger'"
							style="margin-left: 10px"
							size="small"
							>{{ appraisalForm.statusName }}</el-tag
						>
					</h3>
				</div>
				<div style="padding-right: 20%">
					<el-row>
						<el-col :span="12">
							<el-form-item
								label="状态"
								prop="status"
								:rules="{ required: $route.name != 'interviewAppraisalInfo', message: '请选择面试结果', trigger: 'change' }"
							>
								<el-radio-group v-model="appraisalForm.status" :disabled="$route.name == 'interviewAppraisalInfo'">
									<el-radio label="2">通过</el-radio>
									<el-radio label="3">不通过</el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
						<el-col :span="9" style="margin-left: 11.75%">
							<el-form-item
								label="综合得分"
								prop="compositeScore"
								:rules="{ required: $route.name != 'interviewAppraisalInfo', message: '请填写综合得分', trigger: 'blur' }"
							>
								<el-input-number
									v-model="appraisalForm.compositeScore"
									:disabled="$route.name == 'interviewAppraisalInfo'"
									:min="0"
									:max="100"
									:controls="false"
								></el-input-number>
							</el-form-item>
						</el-col>
					</el-row>
					<el-form-item
						label="综合评价"
						prop="compositeEvaluation"
						:rules="{ required: $route.name != 'interviewAppraisalInfo', message: '请输入综合评价', trigger: 'blur' }"
					>
						<el-input
							show-word-limit
							maxlength="200"
							style="width: 98.2%"
							v-model="appraisalForm.compositeEvaluation"
							:disabled="$route.name == 'interviewAppraisalInfo'"
							placeholder="请输入综合评价"
							type="textarea"
							:rows="3"
						></el-input>
					</el-form-item>
					<el-form-item label="附件" v-if="$route.name != 'interviewAppraisalInfo' || appraisalForm.enclosure.length > 0">
						<el-upload
							v-if="$route.name != 'interviewAppraisalInfo'"
							action
							multiple
							:disabled="$route.name == 'interviewAppraisalInfo'"
							:http-request="handleUpload"
							:on-exceed="handleExceed"
							:class="$route.name != 'interviewAppraisalInfo' ? 'upload-list' : 'upload-list translate'"
							:limit="5"
							:on-preview="handleFile"
							accept=".pdf, .doc, .jpg, jpeg, .png, .docx, .JPG, JPEG, .PNG, .PDF, .DOC, .DOCX"
							name="imgFile"
							:file-list="appraisalForm.enclosure"
						>
							<el-button type="primary" size="mini">点击上传</el-button>
						</el-upload>
						<div v-else>
							<div class="docContainer" style="width: 100%" v-for="(file, index) in appraisalForm.enclosure" :key="index">
								<div class="docContent">
									<img src="/static/images/fj.png" style="width: 40px; height: 40px" />
									<div style="width: auto; height: 60px; margin-left: 5px">
										<span class="fileName">{{ file.name }}</span>
										<div style="text-align: left; transform: translateY(-18px)">
											<el-button
												v-show="isImageFileName(file.url)"
												type="text"
												class="media-print__hidden"
												@click="picPreview(file.url)"
												>预览</el-button
											>
											<a
												v-show="!isImageFileName(file.url)"
												class="media-print__hidden"
												style="color: #409eff; font-size: 14px; margin-right: 10px"
												target="_blank"
												:href="'http://view.officeapps.live.com/op/view.aspx?src=' + file.url"
												>预览</a
											>
											<el-button type="text" class="media-print__hidden" @click="downLoadFile(file.url)">下载</el-button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<span class="el-upload__tip" v-if="$route.name != 'interviewAppraisalInfo'"
							>文件格式为建议上传jpg/png/pdf/word格式，大小在5MB以内</span
						>
					</el-form-item>
				</div>
			</el-form>
		</section>
		<section class="sections-container">
			<div class="section-wrap">
				<h3 class="title-icon">人才评估</h3>
			</div>
			<talentAssessment :iscomponent="true" :form="talentForm"></talentAssessment>
		</section>
		<el-dialog :visible.sync="dialogVisible">
			<img width="100%" :src="dialogImageUrl" alt />
		</el-dialog>
	</div>
</template>
<script>
// import UploadedFile from '../../components/UploadedFile';
import { uploadImg } from '@/api/common.js';
import talentAssessment from '../talent-assessment/assess/index';
import { interviewEvaluate, interviewReportSave, interviewRecords, talentSelectUserAssessReport } from '../../api/recruitment_allocation';
import { isImageFileName } from '@/utils/util.js';
export default {
	components: {
		// UploadedFile,
		talentAssessment
	},
	data() {
		return {
			loading: false,
			appraisalForm: {
				processId: this.$route.params.processId,
				arrangeId: this.$route.params.arrangeId,
				status: '2',
				statusName: '',
				compositeScore: '',
				enclosure: [],
				compositeEvaluation: ''
			},
			talentForm: null,
			dialogImageUrl: '',
			dialogVisible: false
		};
	},
	mounted() {
		// if (this.$route.name == "interviewAppraisalInfo") {
		interviewRecords({
			arrangeId: this.$route.query.arrangeId || this.$route.params.arrangeId,
			processId: this.$route.query.processId || this.$route.params.processId
		}).then((res) => {
			if (res._responseStatusCode == 0) {
				delete res._responseStatusCode;
				res.map((it, index) => {
					// if (it.staffId == this.$store.state.app.staffId) {
					this.appraisalForm.compositeScore = res[index].compositeScore;
					this.appraisalForm.compositeEvaluation = res[index].compositeEvaluation;
					this.appraisalForm.enclosure = res[index].enclosure ? JSON.parse(res[index].enclosure) : [];
					this.appraisalForm.status = res[index].status;
					this.appraisalForm.statusName = res[index].statusName;
					talentSelectUserAssessReport({
						id: res[index].talentId,
						type: '0'
					}).then((res) => {
						if (res._responseStatusCode == 0) {
							delete res._responseStatusCode;
							this.talentForm = res;
						}
					});
					// }
				});

				// for(let key in this.appraisalForm){
				//     for (let key1 in res){
				//         if(key==key1&&key!='enclosure'&&res[key1]){
				//             this.appraisalForm[key]=res[key1]
				//         }
				//     }
				// }
			}
		});
		// } else {
		//   this.talentForm = JSON.parse(localStorage.getItem("Data"));
		// }
	},
	methods: {
		handleUpload(options) {
			const { file } = options;

			const fileReader = new FileReader();

			fileReader.readAsDataURL(file);
			fileReader.onload = async (e) => {
				const request = {
					accList: [
						{
							accName: file.name,
							base64Code: e.target.result
						}
					]
				};

				const res = await uploadImg({ request });

				if (res[0]) {
					this.appraisalForm.enclosure.push({
						name: file.name,
						url: res[0].httpUrl
					});
				}
			};
		},
		handleExceed(files, filelist) {
			if (files.length > 5 || filelist.length === 5 || files.length + filelist.length > 5) {
				this.$message({
					message: '上传文件数量不能超过五个',
					type: 'warning'
				});
			}
		},
		handleFile(file) {
			window.open(file.url);
		},
		submit(row, evaluateType) {
			localStorage.setItem('Data', JSON.stringify(row));
			this.loading = true;
			if (evaluateType == 2) {
				interviewEvaluate({
					...this.appraisalForm,
					saveStatus: evaluateType == '2' ? '0' : '1',
					enclosure: JSON.stringify(this.appraisalForm.enclosure)
				}).then((res) => {
					if (res && res.code == 0) {
						row.evaluateType = evaluateType;
						row.processId = this.appraisalForm.processId;
						interviewReportSave({ ...row }).then((res) => {
							this.loading = false;
							if (res && res._responseStatusCode == 0) {
								this.$message.success(evaluateType == 2 ? '保存成功' : '提交成功');
								this.$router.push({ path: '/staff/recruit' });
							}
						});
					}
				});
			} else {
				this.$refs.appraisalForm.validate((valid) => {
					if (valid) {
						interviewEvaluate({
							...this.appraisalForm,
							saveStatus: evaluateType == '2' ? '0' : '1',
							enclosure: JSON.stringify(this.appraisalForm.enclosure)
						}).then((res) => {
							if (res && res.code == 0) {
								row.evaluateType = evaluateType;
								row.processId = this.appraisalForm.processId;
								interviewReportSave({ ...row }).then((res) => {
									this.loading = false;
									if (res && res._responseStatusCode == 0) {
										this.$message.success(evaluateType == 2 ? '保存成功' : '提交成功');
										this.$router.push({ path: '/staff/recruit' });
									}
								});
							}
						});
					}
				});
			}
		},
		isImageFileName,
		makeFileList(json) {
			try {
				const fileList = JSON.parse(json);

				return fileList;
			} catch (err) {
				return [];
			}
		},
		downLoadFile(url) {
			window.open(url);
		},
		picPreview(url) {
			this.dialogImageUrl = url;
			this.dialogVisible = true;
		}
	}
};
</script>
<style lang="scss">
.interviewAppraisal {
	.titlebox {
		overflow: hidden;
		padding: 10px 40px;
		> .el-button {
			transform: translateY(0);
		}
		.title {
			line-height: 38px;
		}
	}
	.el-upload-list {
		.el-upload-list__item {
			width: 50%;
			margin: 7px 0;
			padding: 5px;
			background-color: #f4f4f5;
			label i,
			.el-icon-close {
				transform: translate(-5px, 5px);
			}
		}
		.el-upload-list__item:hover {
			background-color: #f5f7fa;
		}
	}
	.translate {
		.el-upload-list {
			transform: translateY(-43px);
		}
	}
	.sections-container {
		border-radius: 4px;
		padding: 20px 60px;
		margin-bottom: 20px;
		background-color: #fff;
		.title-icon {
			font-size: 16px;
			margin: 0 0 20px 30px;
			&:before {
				display: inline-block;
				width: 8px;
				height: 20px;
				background: #498df0;
				content: ' ';
				border-radius: 5px;
				vertical-align: middle;
				transform: translateY(-1px);
				margin-right: 5px;
			}
		}
		.talentBox .app-container {
			margin: 0 0 20px 30px;
			padding: 0 35px;
			.buttonBox {
				text-align: center;
			}
		}
		.qualityevaluation .ia {
			.el-radio {
				display: block;
				margin: 10px 0;
			}
		}
	}
}
</style>
<style lang="scss" scoped>
::v-deep.el-form-item__label {
	font-weight: normal;
}
.media-print__hidden {
	font-size: 12px;
}
.docContainer {
	.docContent {
		display: flex;
		align-items: center;
		margin-left: 10px;
		min-width: 120px;
	}
	.fileName {
		font-size: 13px;
		text-align: left;
	}
}
</style>
